export const FETCH_TIME_OUT = 20000; // millisecond
export const MESSAGE_TYPES = {
	NEXT: 'next',
	PREV: 'prev',
	VIEW: 'view',
	SCHEDULE: 'schedule',
	REFRESH: 'refresh',
	ADD_EVENT: 'addEvent',
	UN_SELECTDATE: 'unSelectDate',
	SCROLL_TO_TIME: 'scrollToTime',
	UPDATE_RECURR_JOB: 'updateRecurringJob',
	TOGGLE_WEEKENDS: 'toggleWeekends',
	TOGGLE_SELECT_TIME: 'toggleSelectTime',
	MOVE_TO_SLIDE: 'moveToSlide',
	TODAY: 'today',
	DELETE_TIME_OFF: 'deleteTimeOff',
	ERROR: 'error',
	SHOW_LOADING: 'showLoading',
	ZOOM: 'zoom',
};

export const SOCKET_EVENTS = {
	CONNECT: 'connect',
	CONNECTED: 'connected',
	DISCONNECT: 'disconnect',
	CONNECT_ERROR: 'connect_error',
	CONNECT_FAILED: 'connect_failed',
};

export const SOCKET_NAMESPACES = {
	JOB: 'job',
	STATUS: 'status',
	MOVE: 'move',
	DELETE: 'delete',
	CREATE: 'create',
	RESIZE: 'resize',
	UPDATE: 'update',
};

export const JobStatusColor = new Map([
	['0', '#BBB'], // unconfirmed
	['1', '#92cc35'], // confirmed
	['2', '#79a6ef'], // completed
	['3', '#ffa500'], // reschedule
	['4', '#FFCB6D'], //pending
	['5', '#FB9C86'], // canceled
	['6', '#929ba9'],
	['7', '#f670af'], // booking
]);

export const ADMIN = 'admin';
export const MOVE_ADD_RECURRING = 'moveAllRecurring';
export const APP_EDIT_JOB = 'appEditJob';

export const TIME_GRID_DAY = 'timeGridDay'; //agendaDay
export const TIME_GRID_WEEK = 'timeGridWeek'; // agendaweek
export const DAY_GRID_MONTH = 'dayGridMonth'; //month
export const TIME_GRID_3_DAYS = 'timeGrid3Days'; //agenda3Days
export const TIME_GRID_4_DAYS = 'timeGrid4Days'; //agenda4Days

export const AGENDA_MODES = {
	timeGridDay: 'agendaDay',
	timeGridWeek: 'agendaWeek',
	dayGridMonth: 'month',
	timeGrid3Days: 'agenda3Days',
	timeGrid4Days: 'agenda4Days',
};

export const TYPE_CLICK = {
	EVENT: 1,
	DATE: 2,
	ADD_JOB: 3,
	AUTO_APPLY: 4,
};

export const JOB_TYPES = {
	RECURR_JOB: 'recurr_job',
	JOB: 'job',
};

export const TYPE_MOVE = {
	ONLY: 3,
	ALL: 1,
	CANCEL: 2,
};

export const AUTO_APPLY = {
	ONLY: 0,
	ALL: 1,
	NULL: 3,
};

export const TIME_OFF = 'timeoff';

export const viewsConfig = {
	day: {
		// height: '100px',
	},
	week: {
		type: 'timeGrid',
		duration: { days: 3 },
	},
	month: {
		eventLimit: 6,
	},
	dayGridMonth: {
		eventLimit: 6,
	},
	timeGrid3Days: {
		type: 'timeGrid',
		duration: { days: 3 },
	},
	timeGrid4Days: {
		type: 'timeGrid',
		duration: { days: 4 },
	},
};

export const DOT_NUMBER_EVENTS = {
	TYPE: {
		WEEK: 1,
		MONTH: 2,
		YEAR: 3,
	},
};
