import { call, takeLeading, takeLatest, race, take, select } from 'redux-saga/effects';

import FetchAPI from 'core/utils/FetchAPI';

import {
	FETCH_JOB_LIST,
	FETCH_COUNT_JOB,
	FETCH_MOVE_EVENT,
	FETCH_RESIZE_EVENT,
	STOP_FETCH_MOVE_EVENT,
	STOP_FETCH_RESIZE_EVENT,
} from 'core/redux/actions/ActionTypes';

import { JOB_LIST, COUNT_JOB, MOVE_JOB, MOVE_TIME_OFF, RESIZE_JOB, RESIZE_TIME_OFF } from 'app/constants/Api';

import { TIME_OFF } from 'app/constants/App';

function* fetchJobList(action) {
	try {
		const results = yield call(FetchAPI.getFetch, JOB_LIST, action.params, action.token, { 'api-version': '1.0' });
		if (results.success) {
			action.onSuccess(results.events || []);
		} else {
			action.onFailed(results.message || []);
		}
	} catch (error) {
		action.onFailed(error.message || []);
	}
}

function* fetchCountJob(action) {
	try {
		const results = yield call(FetchAPI.getFetch, COUNT_JOB, action.params, action.token);

		if (results.success) {
			action.onSuccess(results.days ?? {});
		} else {
			action.onFailed(results.message ?? '');
		}
	} catch (error) {
		action.onFailed(error.message ?? '');
	}
}

function* fetchResizeEvent(action) {
	const token = yield select(store => store.accessToken);
	const typeEvent = action.typeEvent;
	const body = action.params;
	try {
		const response = yield call(
			FetchAPI.postFetch,
			typeEvent == TIME_OFF ? RESIZE_TIME_OFF : RESIZE_JOB,
			body,
			token,
		);
		if (response?.success) {
			action.onSuccess(body.id, body.moveall, typeEvent);
		} else {
			action.onFailed(body.id, response?.message ?? 'Update Resize Job Failed!');
		}
	} catch (error) {
		action.onFailed(body.id, error.message ?? '');
	}
}

function* fetchMoveEvent(action) {
	const token = yield select(store => store.accessToken);
	const typeEvent = action.typeEvent;
	const body = action.params;
	try {
		const response = yield call(FetchAPI.postFetch, typeEvent == TIME_OFF ? MOVE_TIME_OFF : MOVE_JOB, body, token);
		if (response?.success) {
			action.onSuccess(body.id, body.moveall, typeEvent);
		} else {
			action.onFailed(body.id, response?.message ?? 'Update Resize Job Failed!');
		}
	} catch (error) {
		action.onFailed(body.id, error.message ?? '');
	}
}

export function* watchFetchJobList() {
	yield takeLatest(FETCH_JOB_LIST, fetchJobList);
}

export function* watchFetchCountJob() {
	yield takeLeading(FETCH_COUNT_JOB, fetchCountJob);
}

export function* watchFetchResizeEvent() {
	yield takeLeading(FETCH_RESIZE_EVENT, function* (...args) {
		yield race({
			task: call(fetchResizeEvent, ...args),
			cancel: take(STOP_FETCH_RESIZE_EVENT),
		});
	});
}

export function* watchFetchMoveEvent() {
	yield takeLeading(FETCH_MOVE_EVENT, function* (...args) {
		yield race({
			task: call(fetchMoveEvent, ...args),
			cancel: take(STOP_FETCH_MOVE_EVENT),
		});
	});
}
