import axios from 'axios';
import { API_DOMAIN } from 'configs/environments';
import { FETCH_TIME_OUT } from 'app/constants/App';

const instance = axios.create();
instance.defaults.timeout = FETCH_TIME_OUT;
instance.defaults.baseURL = API_DOMAIN;

const FetchAPI = {
	getFetch: (url, params, token, headerConfig, requestConfig) => {
		try {
			return instance
				.get(url, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						token: token,
						...headerConfig,
					},
					params: params,
					...requestConfig,
				})
				.then(response => {
					if (response.status === 200) {
						return response.data;
					} else {
						return {
							success: false,
							message: 'Connect server failed!',
						};
					}
				})
				.catch(error => {
					return {
						success: false,
						message: error.message ?? '',
					};
				});
		} catch (error) {
			return {
				success: false,
				message: error.message ?? '',
			};
		}
	},
	postFetch: (url, body, token, headerConfig, requestConfig) => {
		try {
			return instance
				.post(
					url,
					{
						...body,
					},
					{
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							token: token,
							...headerConfig,
						},
						...requestConfig,
					},
				)
				.then(response => {
					if (response.status === 200) {
						return response.data;
					} else {
						return {
							success: false,
							message: 'Connect server failed!',
						};
					}
				})
				.catch(error => {
					return {
						success: false,
						message: error.message ?? '',
					};
				});
		} catch (error) {
			return {
				success: false,
				message: error.message ?? '',
			};
		}
	},
};

export default FetchAPI;
